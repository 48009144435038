import { graphql, Link } from "gatsby";
import * as React from "react";
import * as style from "./index.module.scss";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
// import useInterval from "react-useinterval";
import { Helmet } from "react-helmet";

// markup
const IndexPage = ({
  data: {
    allFile: { edges: galleryImages },
  },
}) => {
  // const [numberOfGalleryImages, setNumberOfGalleryImages] = React.useState(4);
  // const [galleryImageNumber, setGalleryImageNumber] = React.useState(0);
  // const [currentImageStyle, setCurrentImageStyle] = React.useState({});

  // const updateImage = () => {
  //   setGalleryImageNumber((galleryImageNumber + 1) % numberOfGalleryImages);
  // };

  // React.useEffect(() => {
  //   setCurrentImageStyle({
  //     "--galleryImage": "url('/gallery" + (galleryImageNumber + 1) + ".jpeg')",
  //   });
  // }, [galleryImageNumber]);

  // useInterval(updateImage, 5000);

  const contentHeader = React.useRef();

  const scrollToMainContent = (e) => {
    contentHeader.current.scrollIntoView({ behavior: "smooth" });
  };

  const seo = {
    title: "The Backyard Brothers | Construction company",
    description:
      "From outdoor patios to fences, decks and everything in between, Tyler and Andrew Klassen bring your renovation dreams to life.",
    image: "/social_media.png",
    url: "https://backyardbrothers.ca/",
  };

  return (
    <div className={style.wrapper}>
      <Helmet>
        <title>{seo.title}</title>
        <link href="/favicon.ico" rel="icon" type="image/x-icon" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
      <header className={`${style.section} ${style.staticContent}`}>
        <Link to="/">
          <StaticImage src="../images/logo.png" alt="Logo" width={125} />
        </Link>
      </header>
      <section className={`${style.section}`}>
        <div className={style.mainHolder}>
          <div className={style.title}>
            <h1>The Backyard Brothers</h1>
            <a href="https://www.facebook.com/profile.php?id=100066607376652" rel="noreferrer nofollow" target="_blank">
              <StaticImage src="../images/fb.png" alt="Logo" width={175} class={style.fb} />
            </a>
          </div>
        </div>
      </section>
      <main className={`${style.section} ${style.staticContent}`}>
        <div>
          <h2 ref={contentHeader}>
            From outdoor patios to fences, decks and everything in between
          </h2>
          <div className={style.center}>
            <StaticImage
              src="../images/tools.png"
              alt="Tools"
              width={75}
              className={style.center}
            />
          </div>
          <p>
          The Backyard Brothers are your local experts in the Niagara Region to meet your needs of bringing your backyard dreams to life. Specializing in Decks, fences and concrete the Bros can use their unique combined skill set to create a complete make over of your outdoor space. We focus on utmost precision and craftsmanship when it comes to carpentry and wood work. With concrete we value the most clean and eye catching finishes whether that's a special stamped, exposed aggregate or broom finished concrete patio or driveway. With 15+ combined years of experience in construction the brothers don't shy away from special projects such as unique overhangs/pergolas, basement walkouts and egress windows! Give us a shout, you won't be disappointed!
          </p>
          <h3>Contact for free quote</h3>
          <p>
            Call <a href="tel:+9059321129">(905) 246-7022</a> or email{" "}
            <a href="mailto:backyardbrothersniagara@gmail.com">
              backyardbrothersniagara@gmail.com
            </a>
            .
          </p>
          <h2>Gallery</h2>
          <div className={style.photoGallery}>
            {galleryImages.map((image) => (
              <GatsbyImage
                key={image.node.id}
                image={getImage(image.node)}
                alt="Gallery image"
                className={style.photo}
              />
            ))}
          </div>
          <h2>
            <abbr title="Established">Est.</abbr> 1998
          </h2>
          <StaticImage src="../images/brothers.jpeg" alt="Childhood photo" />
        </div>
      </main>
      <footer>&copy; { new Date().getFullYear() } The Backyard Brothers. All rights reserved.</footer>
    </div>
  );
};

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;

export default IndexPage;
